import React, { useState, useEffect } from 'react';
import './App.css';
import backgroundMusic from './assets/background-music.mp3';
import backgroundMusic_ from './assets/background-music_.mp3';

const questions = [
    {
        question: "Какой сегодня день?",
        options: ["15.11.2042", "15.11.2024", "11.15.2024", "11.15.2024"],
        answer: "15.11.2024",
        money: 1000,
    },
    {
        question: "Сколько шоколадок \"Аленка\" было отдано за 6 полторашек самогонки?",
        options: ["1", "15", "3", "6"],
        answer: "6",
        money: 10000,
    },

    {
        question: "Какой адрес первой съемной хаты в которой мы жили?",
        options: ["Шаляпина 15", "Шаляпина 11", "Шаляпина 16", "Шаляпина 14"],
        answer: "Шаляпина 15",
        money: 50000,
    },
    {
        question: "Первый бизнес?",
        options: ["Курсовые", "Пиздил матрасы", "Торговал людьми", "Продавал протеином"],
        answer: "Пиздил матрасы",
        money: 100000,
    },
    {
        question: "Сколько подписчиков в *запрещённой в России социальной сети*?",
        options: ["201", "1000000", "287", "100"],
        answer: "287",
        money: 400000,
    },
    {
        question: "Какой был препод по матану на первом экзамене?",
        options: ["Попов", "Майстренко", "Мазова", "Филькин"],
        answer: "Мазова",
        money: 250000,
    },
    
    {
        question: "Сколько лайков под аватаркой ВКонтакте?",
        options: ["54", "10", "42", "1"],
        answer: "42",
        money: 600000,
    },
    {
        question: "В какой ночной клуб мы ходили в Казани?",
        options: ["Burlesque", "Arena", "Ravez", "Zажигалка"],
        answer: "Arena",
        money: 800000,
    },
    {
        question: "Когда ты сделал Рите предложение?",
        options: ["27.12.2018", "26.12.2018", "26.12.2019", "26.19.2012"],
        answer: "27.12.2018",
        money: 1000000,
    },
];

function App() {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
    const [isCongratulated, setIsCongratulated] = useState(false);
    const [timeLeft, setTimeLeft] = useState(60);
    const [isTimerStarted, setIsTimerStarted] = useState(false);
    const [usedHints, setUsedHints] = useState({
        fiftyFifty: false,
        phoneAFriend: false,
        audienceHelp: false,
    });
    const [moneyEarned, setMoneyEarned] = useState(0);
    const [correctAnswerFlash, setCorrectAnswerFlash] = useState(false);

    const startTimer = () => {
        setIsTimerStarted(true);
        setTimeLeft(60);
    };

    useEffect(() => {
        if (isTimerStarted && timeLeft > 0) {
            const timer = setInterval(() => {
                setTimeLeft(prevTime => prevTime - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [isTimerStarted, timeLeft]);

    const handleAnswer = (answer) => {
        // Проверка на правильный ответ в зависимости от того, использовалась ли подсказка 50 на 50
        const isAnswerCorrect = usedHints.fiftyFifty ? true : answer === questions[currentQuestionIndex].answer;

        if (isAnswerCorrect) {
            setMoneyEarned(prevMoney => questions[currentQuestionIndex].money);
            if (currentQuestionIndex === questions.length - 1) {
                setIsCongratulated(true);
            } else {
                setCorrectAnswerFlash(true);
                setTimeout(() => {
                    setCorrectAnswerFlash(false);
                    setCurrentQuestionIndex(prevIndex => prevIndex + 1);
                    startTimer();
                    resetHints();
                }, 1000);
            }
        }
    };
    
    const startGame = () => {
        const audio = new Audio(backgroundMusic);
        audio.loop = true;
        audio.play();
        setCurrentQuestionIndex(0);
        audio.pause();

        const audio_ = new Audio(backgroundMusic_);
        audio_.loop = true;
        audio_.play();
        setIsCongratulated(false);
        startTimer();
        resetHints();
        setMoneyEarned(0);
    };

    const handleFiftyFifty = () => {
        if (!usedHints.fiftyFifty) {
            const correctIndex = questions[currentQuestionIndex].options.indexOf(questions[currentQuestionIndex].answer);
            const wrongAnswers = questions[currentQuestionIndex].options.filter((_, index) => index !== correctIndex);
            const randomIndex = Math.floor(Math.random() * wrongAnswers.length);
            const remainingOptions = [questions[currentQuestionIndex].answer, wrongAnswers[randomIndex]];
            questions[currentQuestionIndex].options = remainingOptions; // Обновляем варианты ответов
            setUsedHints(prevHints => ({ ...prevHints, fiftyFifty: true }));
        }
    };

    const handlePhoneAFriend = () => {
        if (!usedHints.phoneAFriend) {
            const correctIndex = questions[currentQuestionIndex].options.indexOf(questions[currentQuestionIndex].answer);
            const randomFriendAnswer = Math.random() < 0.5 ? correctIndex : Math.floor(Math.random() * questions[currentQuestionIndex].options.length);
            alert(`Ваш друг думает, что правильный ответ: ${questions[currentQuestionIndex].options[randomFriendAnswer]}`);
            setUsedHints(prevHints => ({ ...prevHints, phoneAFriend: true }));
        }
    };

    const handleAudienceHelp = () => {
        if (!usedHints.audienceHelp) {
            const correctIndex = questions[currentQuestionIndex].options.indexOf(questions[currentQuestionIndex].answer);
            const audienceResponses = Array(questions[currentQuestionIndex].options.length).fill(0);
            audienceResponses[correctIndex] = Math.floor(Math.random() * 51) + 50;

            audienceResponses.forEach((_, index) => {
                if (index !== correctIndex) {
                    audienceResponses[index] = Math.floor(Math.random() * 50);
                }
            });

            alert(`Помощь зала: ${questions[currentQuestionIndex].options.map((option, index) => `${option}: ${audienceResponses[index]}%`).join(", ")}`);
            setUsedHints(prevHints => ({ ...prevHints, audienceHelp: true }));
        }
    };

    const resetHints = () => {
        setUsedHints({
            fiftyFifty: false,
            phoneAFriend: false,
            audienceHelp: false,
        });
    };

    if (currentQuestionIndex === -1) {
        return (
            <div className="App">
                <div className="welcome">
                    <h1>Добро пожаловать в</h1>
                    <h2>Игру "Кто хочет стать миллионером?"</h2>
                    <button className="start-button" onClick={startGame}>Начать игру</button>
                </div>
            </div>
        );
    }

    return (
        <div className="App">
            <div className="money-scale">
                Ты заработал: {moneyEarned} ₽
            </div>
            {isCongratulated ? (
                <div className="congratulation">
                    <h1>Поздравляем, Сань!</h1>
                    <h2>Ты выиграл конверт!</h2>
                    <h2>Миллион сам заработаешь!</h2>
                    <h2>Пусть рядом будут верные друзья, а каждый новый день приносит вдохновение и положительные эмоции. Не бойся мечтать и ставить перед собой смелые цели — я уверен, ты всё сможешь! С днем рождения!</h2>
                </div>
            ) : (
                <div className="question-container">
                    <div className={`question-text ${correctAnswerFlash ? 'correct' : ''}`}>
                        {questions[currentQuestionIndex].question}
                    </div>
                    <p>Осталось времени: {timeLeft} сек</p>
                    <div className="options">
                        {questions[currentQuestionIndex].options.map((option, index) => (
                            <button 
                                key={index} 
                                className={`option-button ${correctAnswerFlash && option === questions[currentQuestionIndex].answer ? 'correct' : ''}`} 
                                onClick={() => handleAnswer(option)}
                            >
                                {option}
                            </button>
                        ))}
                    </div>
                    <div className="hints">
                        <button onClick={handleFiftyFifty} disabled={usedHints.fiftyFifty}>50 на 50</button>
                        <button onClick={handlePhoneAFriend} disabled={usedHints.phoneAFriend}>Звонок другу</button>
                        <button onClick={handleAudienceHelp} disabled={usedHints.audienceHelp}>Помощь зала</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default App;